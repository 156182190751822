import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
 

class AreaEnti extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Area enti"}];
    const intro = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const aral = this.props.data.aral;
    const datahero = {
      "title": "Centro Zootecnico Didattico Sperimentale",
      "subtitle": "Area enti",
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Area enti"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero} cover={hero.childImageSharp}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              {!!intro && 
                <div dangerouslySetInnerHTML={{ __html: intro.testo.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!intro.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{intro.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract" dangerouslySetInnerHTML={{ __html: intro.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
              {!!intro.attachment &&
                <DocumentiUtili section="Documenti Utili" data={intro.attachment}/>
              }
            </div>
          </div>
        </div>

        <div className="box-area-subhome">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                <img src="/images/contents/bear_big.png" alt="" className="box-area-subhome__img" />
                <div className="box-area-subhome__title">Hai bisogno di maggiori informazioni?</div>
                <a href="mailto:segreteriacentrovet@unimi.it" title="Contattaci" className="btn-link">Contattaci</a>
              </div>
            </div>
          </div>
        </div>

        <div className="partner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Enti convenzionati</h1>
              </div>
            </div>
            <div className="row text-center">
             
              <div className="col-6 col-md-4  text-center">
                <a href="https://www.aral.lom.it/" target="_blank"  rel="noopener noreferrer" title="ASSOCIAZIONE REGIONALE ALLEVATORI DELLA LOMBARDIA" className="partner__link">
                  <Img fluid={aral.childImageSharp.fluid}/>
                  <span className="partner__name">ASSOCIAZIONE REGIONALE ALLEVATORI DELLA LOMBARDIA</span>
                </a>
              </div>
              
            </div>
          </div>
        </div>      

      </Layout>
    )
  }

}

export default AreaEnti;

export const query = graphql`
query AreaEntiQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "4CHME77lgNngR3KD1lS98z"}) {
  	testo {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
  }
  hero: file(relativePath: {eq: "img_area-enti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  aral: file(relativePath: {eq: "aral.jpg"}) {
    childImageSharp{
      fluid(maxWidth: 400){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`